import styles from "../assets/styles/LogoButton.module.scss";
import rechallenge from '../assets/images/rechallenge.png';
import lock from '../assets/images/lock.png';

export default function LogoButtonModule({buttonCase, onClick}) {
    switch(buttonCase){
        case "SliderChallenge": 
        return <SliderChallengeButton onClick={onClick}/>;
        case "SliderReChallenge": 
        return <SliderReChallengeButton onClick={onClick}/>;
        case "SliderChallengeLockedButton": 
        return <SliderChallengeLockedButton onClick={onClick}/>;
        case "TutorialNextButton": 
        return <TutorialNextButton onClick={onClick}/>;

    }
}

const SliderChallengeButton=({onClick})=> {
    return (  
        <button className={styles.challenge_btn_con} onClick={onClick}>
            <div className={styles.challenge_icon_con}>
                 <img src={lock} className={styles.lock_icon} alt="lock"/>
            </div>
            <div className={styles.challenge_btn_text}>立即挑戰</div>
        </button>
    );
  }

  const SliderReChallengeButton=({onClick})=> {
    return (  
        <button className={styles.rechallenge_btn_con} onClick={onClick}>
            <div className={styles.rechallenge_icon_con}>
                <img src={rechallenge} className={styles.rechallenge_icon} alt="rechallenge"></img>
            </div>
            <div className={styles.challenge_btn_text}>再次挑戰</div>
        </button>
    );
  }

  const TutorialNextButton=({onClick})=> {
    return (  
        <div className={styles.tutor_btn_con}>
            <button onClick={onClick} className={styles.tutor_btn}>繼續</button>
        </div>
    );
  };
  

  const SliderChallengeLockedButton=({onClick})=> {
    return (  
        <button className={styles.lock_btn_con} onClick={onClick}>
            <div className={styles.lock_icon_con}>
                <img src={lock} className={styles.lock_icon} alt="lock"></img>
            </div>
            <div className={styles.challenge_btn_text}>玩埋上一關先</div>
        </button>
    );
  };
