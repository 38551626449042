import bgLanding from '../assets/images/bg_landing.png';
import bgLanding2 from '../assets/images/bg_landing_2.png';
import manningLogo from '../assets/images/logo.png';
import videoCon from '../assets/images/video_con.png';
import santaTitle from '../assets/images/santa_title.png';
import giftLeft from '../assets/images/gift_left.png';
import giftRight from '../assets/images/gift_right.png';
import santaKid from '../assets/images/santa_kid.png';
import lightTop from '../assets/images/light_top.png';
import playBtn from '../assets/images/play_btn.png';
import recruitTitle from '../assets/images/recruit_title.png';
import quizBg from '../assets/images/quiz_bg.png';
import lightBottom from '../assets/images/light_bottom.png';
import quizConBg from '../assets/images/quiz_con_bg_2.png';
import quizArrow from '../assets/images/quiz_arrow.png';
import photoConBg from '../assets/images/photo_con_bg.png';
import photoTitle from '../assets/images/photo_title.png';
import recruitBtn from '../assets/images/recruit_btn.png';
import santaPeople from '../assets/images/santa_people.png';

import { useNavigate } from "react-router-dom";


import '../assets/styles/Landing.css';


function Landing() {
  let navigate = useNavigate();
  return (
    <div className="App">
      <body className="App-body">
      <center className="main_center">
        {/* <!-- Bg Con --> */}
        <div className="santa_bg_con"></div>
        <div className="video_bg_con">
             <img src={bgLanding} className="bgLandingImgWidth" alt="bgLandingImgWidth"></img>
        </div> 
        <img src={bgLanding2} className="photo_bg_con" alt="photo_bg_con"></img>
        {/* <!-- Manning Logo Con --> */}
        <div className="manning_logo_con">
            <img className="manning_logo_img" src={manningLogo} alt="manning_logo_img"></img>
        </div>
        {/* <!-- Video Con --> */}
        <div className="video_con">
            <div className="video_deco_con">
                <div className="video_img_con">
                    <img src={videoCon} alt="videoCon"></img>
                </div>
                <div className="video_title_con">
                    <img src={santaTitle} alt="santaTitle"></img>
                </div>
                <img src={giftLeft} className="deco_gift_left" alt="giftLeft"></img>
                <img src={giftRight} className="deco_gift_right" alt="giftRight"></img>
                <div className="video_char_con">
                    <img src={santaKid} alt="santaKid"></img>
                </div>
                <img src={lightTop} className="deco_light_top" alt="lightTop"></img>        
                <div className="video_play_btn_con">
                    <button className="video_play_btn">
                        <div className="video_play_btn_icon">
                            <img src={playBtn} className="playBtnWidth" alt="playBtnWidth"></img>
                        </div>
                        <div className="video_play_btn_text">播放</div>
                    </button>
                </div>     
            </div>
        </div>
        {/* <!-- Recruit Con --> */}
        <div className="recruit_con">
            <div className="recruit_title_con">
                <img src={recruitTitle} alt="recruitTitle"></img>
            </div>
            <div className="recruit_text_bg">
                <div className="recruit_text_con">
                    <p className="recruit_text_title">又到聖誕～</p>
                    <p className="recruit_text_title">年年都等Santa出現送禮物</p>
                    <p className="recruit_text_title">不如今年試吓唔同啲？</p>
                    <p className="recruit_text_content">其實你都可以成為識得分享同「fun」享嘅Santa！萬寧募集人人做Santa，立即參與以下小遊戲，仲有好多萬寧聖誕精選優惠！</p>
                </div>
            </div>
            <a href="./#/slider">
                <div className="recruit_btn"> 
                    <img className='recruit_btn_img' src={recruitBtn} alt="recruitBtn"></img>
                    <div className="recruit_btn_text">立即玩！</div>
                </div>
            </a>
        </div>
        {/* <!-- Quiz Con --> */}
        <div className="quiz_con">
            <img className="quiz_bg" src={quizBg} alt="quiz_bg"></img>
            <img className="deco_light_bottom" src={lightBottom} alt="deco_light_bottom"></img>
            <img className="quiz_con_bg" src={quizConBg} alt="quiz_con_bg"></img>
            <div className="quiz_title_con">
                <p className="quiz_title">你係邊類<br></br>Santa小測驗</p>
            </div>
            <div className="quiz_btn_con">
                <button className="quiz_btn" onClick={() => navigate('/quiz-landing')}>
                    <div className="quiz_btn_text">立即測試</div>
                    <div className="quiz_btn_icon">
                        <img src={quizArrow} alt="quizArrow"></img>
                    </div>
                </button>
            </div>
        </div>
        {/* <!-- Photo Con --> */ }
        <div className="photo_con">
            <img src={photoConBg} className="photo_bg" alt="photoConBg"></img>
            <img src={photoTitle} className="photo_title" alt="photoTitle"></img>
            <div className="photo_gallery_con">
                <div className="photo_gallery">
                    <img className="photo_thumbnail" src={santaPeople} alt="santaPeople"></img>
                    <img className="photo_thumbnail" src={santaPeople} alt="santaPeople"></img>
                    <img className="photo_thumbnail" src={santaPeople} alt="santaPeople"></img>
                    <img className="photo_thumbnail" src={santaPeople} alt="santaPeople"></img>
                    <img className="photo_thumbnail" src={santaPeople} alt="santaPeople"></img>
                    <img className="photo_thumbnail" src={santaPeople} alt="santaPeople"></img>
                    <img className="photo_thumbnail" src={santaPeople} alt="santaPeople"></img>
                    <img className="photo_thumbnail" src={santaPeople} alt="santaPeople"></img>
                    <img className="photo_thumbnail" src={santaPeople} alt="santaPeople"></img>
                </div>
            </div>
            <div className="photo_btn_con">
                <button className="photo_btn">我的獎賞</button>
            </div>
        </div>
    </center>
      </body>
    </div>
  );
}

export default Landing;
