import tutorDummy from '../assets/images/tutor_dummy.png';
import Header from '../components/HeaderModule';
import TutorialContentModule from '../components/TutorialContentModule';
import '../assets/styles/Tutorial.css';
import { useLocation, useParams, useNavigate } from "react-router-dom";

const buttonImage = [tutorDummy, tutorDummy];
const tutorials=[
  {
    link:"/",
    desc:"HOHOHO！笑吓啦喂～過聖誕梗係要好似SANTA咁大笑幾聲，先至有聖誕氣氛！笑一笑，再拍個照～立即selfie，你個樣就會出現SANTA版鬍鬚，人人齊變SANTA啦！"
  },
  {
    link:"/sticker",
    desc:"想show吓你獨一無二嘅個人聖誕風格？不如用特設嘅聖誕sticker自製你嘅心意電子聖誕咭，仲可以upload你嘅自選相片，人人立即share畀朋友！"
  },
  {
    link:"/",
    desc:"送聖誕禮物，講求識揀！立即玩小遊戲，為唔同嘅親友搵返最啱佢心意嘅禮物啦！"
  },
  {
    link:"/share",
    desc:"Share條link俾3個好朋友，可以立即獲得萬寧聖誕鬍鬚襟章 乙個！你嘅朋友成功登入，雙方仲可以額外獲贈鬍鬚襟章 乙個！仲唔快啲人人齊share？"
  }
]

function Tutorial() {
  let navigate = useNavigate();
  let {id}=useParams();
  return (
    <div className="App">
      <body className="App-body">
      <center className="tutorial_center">
        <Header 
          hasTitle={false}
          backBtnOnClick={() => navigate('/slider')}
        />
        <TutorialContentModule 
          index={id} 
          desc={tutorials[id-1].desc} 
          buttonImage={require(`../assets/images/tutor_${id}.png`)} 
          onClick={() => navigate(tutorials[id-1].link)}
        />
    </center>
      </body>
    </div>
  );
}

export default Tutorial;
