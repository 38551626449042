import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import Header from '../components/RelativeHeader';
import styles from "../assets/styles/QuizPage.module.scss";
import { useNavigate } from "react-router-dom";

import headerImg from '../assets/images/quiz/quiz_title_header.png';
import questionBoard from '../assets/images/quiz/question_board.png';

import data from "../constants/quiz.json";

const questions=data.questions
const result=data.result;

function Quiz() {
  const [cur, setCur] = useState(0);
  const [answerList, setAnswerList] = useState([]);

  let navigate = useNavigate();
  const toLetters=(num)=>{
    "use strict";
    var mod = num % 26;
    var pow = num / 26 | 0;
    var out = mod ? String.fromCharCode(64 + mod) : (pow--, 'Z');
    return pow ? toLetters(pow) + out : out;
  }

  const back=(index)=>{
    if(cur>0){
      setAnswerList((current)=>current.filter((e,i)=>i!=cur-1));
      setCur(cur-1);
    }
    else{
      navigate('/quiz-landing')
    }
  }

  const calResult=()=>{
    var typeList=Array(result.length).fill(0);
    //console.log(answerList);
    answerList.forEach((a,i)=>{
        questions[i].answers[a].type.forEach((t,y)=>{
            typeList[t]+=1;
        });
    });
    var max=0;
    var type=0;
    console.log(typeList);
    typeList.forEach((v,i)=>{
        if(v>max){
            max=v;
            type=i;
        }
    })
    return type;
  }

  const pickAnswer=(index)=>{
    setAnswerList(answerList.concat([index]));
    if(cur<questions.length-1){
      setCur(cur+1);
    }
    else{
      navigate('/quiz-result/'+calResult(),{state:{answer:answerList}});
    }
  }

  return (
    <div className={styles.page}>
      <Header
        titleImg={headerImg}
        backBtnOnClick={back}
      />
      <div className={styles.container}>
        <div className={styles.quizDiv}>
          <div className={styles.questionBoard}><img src={questionBoard}/></div>
          <div className={styles.questionHint}>第 {(cur+1)} / {questions.length} 題</div>
          <div className={styles.question} dangerouslySetInnerHTML={{__html:questions[cur].text}} />
        </div>
        <div className={styles.divLine}></div>
        <div className={styles.answersDiv}>
          <div className={styles.innerAnswerDiv}>
            {questions[cur].answers.map((answer,index)=>(
              <button onClick={()=>{pickAnswer(index);}} class={styles.answer}>
                <div>{toLetters(index+1)}</div>
                <div dangerouslySetInnerHTML={{__html:answer.text}}/>
              </button>
            ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quiz;
