import LogoButtonModule from '../components/LogoButtonModule';
import styles from "../assets/styles/SliderImage.module.scss";
import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';



export default function SliderModule({buttonStatus, btnOnClick, sliderImageList}) {
    const SliderItems = sliderImageList.map((d)=>
      <img src={d}  alt={d} className={styles.carousellStyle}></img>
    );
  return (  
    <div className={styles.slider_con}>
            <div className={styles.slider_img}>
                <Carousel autoPlay={false} showArrows={false} showThumbs={false} showIndicators={false} showStatus={false}>
                    {SliderItems}
                </Carousel>
            </div> 
            <LogoButtonModule buttonCase={buttonStatus} onClick={btnOnClick}/>
    </div>
  );
}