import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import styles from "../assets/styles/QuizResultPage.module.scss";
import {useLocation, useParams} from 'react-router-dom';
import html2canvas from "html2canvas";

import headerImg from '../assets/images/logo.png';
import resultTitleImg from '../assets/images/quiz/result_title.png';
import bgTreeImg from '../assets/images/quiz/result_bg_tree.png';
import topLightImg from '../assets/images/quiz/top_light.png';
import middleLightImg from '../assets/images/quiz/middle_light.png';
import bottomLightImg from '../assets/images/quiz/bottom_light.png';
import bgSantaImg from '../assets/images/quiz/result_bg_santa.png';
import manningsSanta from '../assets/images/quiz/mannings_santa.png';
import giftForYou from '../assets/images/quiz/gift_for_you.png';
import giftToSend from '../assets/images/quiz/gift_to_send.png';
import bgTreeBottomImg from '../assets/images/quiz/result_bg_tree_bottom.png';

import data from "../constants/quiz.json";

const questions=data.questions;
const result=data.result;
const products=data.products;


function QuizResult(props){
    const location = useLocation();
    let {id}=useParams();
    console.log(location.state);

    

    const [cur, setCur] = useState(id);

    useEffect(()=>{
        console.log(result[cur]);
    },[cur])

    const storeAsImage=()=>{
        html2canvas(document.querySelector("body")).then(canvas => {
            var dataURL = canvas.toDataURL("image/png");
            var link = document.createElement("a");
            link.download = 'quiz_result.png';
            link.href = dataURL;
            link.click();
        });
    }

    const copyToClip=()=>{
        navigator.clipboard.writeText("https://manningscampaign.com.hk");
        alert("Copied Successfully!");
    }

    const shareOnIG=()=>{
        var link = document.createElement("a");
        link.href = "https://www.instagram.com/create/story";
        link.target="_blank"
        link.click();
        
    }

    return(
        <div id="capture" className={styles.page}>
            <div className={styles.container}>
                <div className={styles.headerImg}>
                    <img className={styles.headerImg} src={headerImg}/>
                </div>
                <div className={styles.bgDiv}>
                    <div className={styles.bgTree}>
                        <img src={bgTreeImg}/>
                    </div>
                    <div className={styles.resultTitle}><img src={resultTitleImg}/></div>
                    <div className={styles.topLight}><img src={topLightImg}/></div>
                    <div className={styles.bgSanta}>
                        <img src={bgSantaImg}/>
                    </div>
                    <div className={styles.bgTreeBottom}>
                        <img src={bgTreeBottomImg}/>
                    </div>
                </div>
                <div className={styles.resultDiv}>
                    <div className={styles.typeDiv}>
                        <div className={styles.typeName}><img src={require(`../assets/images/quiz/result/${cur}.png`)}/></div>
                        <div className={styles.typeDesc}><img src={require(`../assets/images/quiz/result/${cur}_desc.png`)}/></div>
                        <div className={styles.typeDetailsDiv}>
                            <div className={styles.typeStatDiv}>
                                <div className={styles.typeStat}><img src={require(`../assets/images/quiz/result/${cur}_stat.png`)}/></div>
                            </div>
                            <div className={styles.typeDetails} dangerouslySetInnerHTML={{__html:result[cur].desc}}/>
                            <div className={styles.typeFooter}>
                                <div className={styles.typeTags}>
                                    {result[cur].tag.map((t,i)=>(
                                        <div className={styles.typeTag} dangerouslySetInnerHTML={{__html:t}}/>
                                    ))}
                                </div>
                                <div className={styles.tagImg}>
                                    <img src={manningsSanta}/>
                                </div>
                            </div>
                            <div className={styles.middleLight}><img src={middleLightImg}/></div>
                        </div>
                    </div>
                    <div className={styles.productsDiv}>
                        <div className={styles.giftForYouDiv}>
                            <div className={styles.innerDiv}>
                                {result[cur].giftForYou.map((p,i)=>(
                                    <div className={styles.product}>
                                        <div className={styles.productImg}><img src={require(`../assets/images/quiz/products/${p}.png`)}/></div>
                                        <div className={styles.productName} dangerouslySetInnerHTML={{__html:products[p].name}}/>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.divName}><img src={giftForYou}/></div>
                        </div>
                        <div className={styles.giftToSendDiv}>
                            <div className={styles.innerDiv}>
                                {result[cur].giftToSend.map((p,i)=>(
                                    <div className={styles.product}>
                                        <div className={styles.productImg}><img src={require(`../assets/images/quiz/products/${p}.png`)}/></div>
                                        <div className={styles.productName} dangerouslySetInnerHTML={{__html:products[p].name}}/>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.divName}><img src={giftToSend}/></div>
                        </div>
                    </div>
                    <div className={styles.shareDiv}>
                        <div className={styles.btnDiv}>
                            <button onClick={storeAsImage} className={styles.btn1}><span>長按儲存<br/>結果圖片</span></button>
                        </div>
                        <div className={styles.btnDiv}>
                            <button onClick={copyToClip} className={styles.btn2}><span>按此<br/>複製連結</span></button>
                        </div>
                        <div className={styles.btnDiv}>
                            <button onClick={shareOnIG} className={styles.btn3}><span>分享到<br/>IG Story</span></button>
                        </div>
                    </div>
                    <div className={styles.fdDiv}>
                        <div className={styles.fdInnerDiv}>
                            <div className={styles.bestFd}>
                                <div>你嘅好朋友</div>
                                {result[cur].fdList.map((t,i)=>(
                                    <div className={styles.fd}>
                                        <div>
                                            <span style={{backgroundImage:`url(${require("../assets/images/quiz/fd_white_l_"+(i+1)+".svg")})`}}>
                                                {result[t].name}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.richFd}>
                                <div>送禮豪朋友</div>
                                {result[cur].giftFdList.map((t,i)=>(
                                    <div className={styles.fd}>
                                        <div>
                                            <span style={{backgroundImage:`url(${require("../assets/images/quiz/fd_white_r_"+(i+1)+".svg")})`}}>
                                                {result[t].name}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={styles.bottomLight}>
                        <img src={bottomLightImg}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default QuizResult;