import sticker_header_title from "../assets/images/sticker_header_title.png";
import bgSticker from "../assets/images/bg_sticker.png";
import stickerDeco from "../assets/images/sticker_deco.png";
import Header from '../components/HeaderModule';
import CaneModule from '../components/CaneModule';

import { useNavigate } from "react-router-dom";


import '../assets/styles/Sticker.css';


function Sticker() {
  let navigate = useNavigate();
  return (
    <div>
      <body>
      <div className="sticker_center">
        <Header 
          hasTitle={true} 
          titleImage={sticker_header_title} 
          backBtnOnClick={() => navigate('/tutorial/2')}/>
        {/* <!-- Bg Con --> */}
        <img src={bgSticker} className="sticker_bg" alt='bgSticker'></img>  
        <img src={stickerDeco} className="sticker_bg_deco" alt='stickerDeco'></img>
        <CaneModule onDIYClick={() => navigate('/sticker-editor')} onDLClick={null}/>
    </div>
      </body>
    </div>
  );
}

export default Sticker;
