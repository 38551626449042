import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import styles from "../assets/styles/RelativeHeader.module.scss";
import { useNavigate } from "react-router-dom";

import back_img from '../assets/images/back-arrow.svg';

export default function Header({titleImg,backBtnOnClick}){
    let navigate = useNavigate();
    return (
        <div className={styles.header}>
            <div className={styles.left}>
                <button className={styles.backBtn} onClick={backBtnOnClick?backBtnOnClick:() => navigate('/sticker')}>
                    <img src={back_img}></img>
                </button>
            </div>
            <div className={styles.middle}>
                <div className={styles.title}>
                    {titleImg && (
                        <img src={titleImg}></img>
                    )}
                </div>
            </div>
            <div className={styles.right}>
            </div>
            <div className={styles.bottomOverlay}></div>
        </div>
    )
}