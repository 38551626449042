import bgSlider from '../assets/images/bg_slider.png';
import Header from '../components/HeaderModule';
import styles from '../assets/styles/Slider.module.scss';
import SliderModule from '../components/SliderModule';
import sliderDummy from '../assets/images/game_slide_dummy.png';
import slide1 from '../assets/images/game_slide1.png';
import slide2 from '../assets/images/game_slide2.png';
import slide3 from '../assets/images/game_slide3.png';
import slide4 from '../assets/images/game_slide4.png';

import { useNavigate } from "react-router-dom";


const buttonStatusList = ["SliderChallenge", "SliderReChallenge", "SliderChallengeLockedButton"];
const buttonStatus = buttonStatusList[2];
const sliderImageList = [slide1, slide2, slide3,slide4];

function Slider() {
  let navigate = useNavigate();
  return (
    <div>
      <body>
        <center className={styles.slider_center}>
            <Header
              backBtnOnClick={() => navigate('/')}
            />
            <img src={bgSlider} className={styles.slider_bg_1} alt="bgSlider"/>
            <SliderModule buttonStatus={buttonStatus} sliderImageList={sliderImageList}/>
         </center>
      </body>
    </div>
  );
}

export default Slider;
