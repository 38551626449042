import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import Header from '../components/HeaderModule';
import styles from "../assets/styles/QuizLandingPage.module.scss";

import bgImg from '../assets/images/quiz/bg.png';
import quizTitleImg from '../assets/images/quiz/quiz_title.png';
import startQuizImg from '../assets/images/quiz/start_quiz.png';
import floorImg from '../assets/images/quiz/floor.png';
import { useNavigate } from "react-router-dom";

function QuizLanding() {
  let navigate = useNavigate();
  return (
    <div className={styles.page}>
      <Header
        backBtnOnClick={() => navigate('/')}
      />
      <div className={styles.container}>
        <div className={styles.bg}><img src={bgImg}/></div>
        <div className={styles.quizDiv}>
          <div className={styles.quizTitle}><img src={quizTitleImg}/></div>
          <div className={styles.quizDesc}>
            <div className={styles.desc}>
            想知最合你心意嘅禮物？又想送到最啱人心意嘅禮物？立即測試，揭示你獨一無二嘅Santa性格特質！
            </div>
            <div className={styles.startBtnDiv}>
              <a className={styles.startBtn} href={"./#/quiz"}>
                <img src={startQuizImg}/>
              </a>
            </div>
            
          </div>
          
        </div>
        <div className={styles.floor}><img src={floorImg}/></div>
      </div>
    </div>
  );
}

export default QuizLanding;
