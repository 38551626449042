import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import StcikerEditor from '../components/StickerEditor';
import Header from '../components/RelativeHeader';
import "../assets/styles/Sticker.page.css";

import headerImg from '../assets/images/sticker/title.png';


function StickerEditor() {
  return (
    <div className={"page"}>
      <Header
        titleImg={headerImg}
      />
      <StcikerEditor/>
    </div>
  );
}

export default StickerEditor;
