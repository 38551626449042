import bgbtn from '../assets/images/back_btn.png';
import share_header_title from "../assets/images/share_header_title.png";
import bgShare from "../assets/images/bg_share.png";
import share_con_bg from "../assets/images/share_con_bg.png";
import share_gift_img from "../assets/images/share_gift_img.png";

import '../assets/styles/Share.css';


function Share() {
  return (
    <div className="App">
      <body className="App-body">
      <center className="share_center">
        {/* <!-- Header Con --> */}
        <div className="header_con">
            <div className="back_btn_con">
                <img src={bgbtn} className="back_btn" alt='bgbtn'></img>
            </div>
            <div className='header_long_width'>
                <img src={share_header_title} className="header_title_img" alt='share_header_title'></img>
            </div>
            <div className='header_short_width'></div>
        </div>
        {/* <!-- Bg Con --> */}
        <img src={bgShare} className="share_bg" alt='bgShare'></img>
        {/* <!-- Share Con --> */}
        <div className="share_con">
            <div>
                <img src={share_con_bg} className="share_inner_bg" alt='share_con_bg'></img>
                <div className="share_form_wrap">
                    <p className="share_title">仲唔快啲share!？</p>
                    <p className="share_content">你嘅朋友成功登入後，<br></br>雙方都會有額外嘅印花添</p>
                    <div className="share_form_con">
                        <div className="share_form_input">請填寫你親友的名字</div>
                        {/* <!-- Disabled button to share link-->  */}
                        <div className="share_btn sh_btn_dis">分享連結</div>
                    </div>
                    <div className="share_form_con">
                        <div className="share_form_input">Ben Chan</div>
                        {/* <!-- Active button to share link --> */}
                        <div className="share_btn sh_btn_act">分享連結</div>
                    </div>
                    <div className="share_form_con">
                        <div className="share_form_input">Natalie Tang</div>
                        {/* <!-- Already login button status-->  */}
                        <div className="share_btn sh_btn_log">已登入</div>
                        
                    </div>
                </div>
                <img src={share_gift_img} className="share_gift_deco" alt='share_gift_img'></img>
            </div> 
            {/* <!-- Button to retrieve medal-->          */}
            <button className="medal_btn_con medal_btn_get">
                <div className="medal_btn_text">領取鬍鬚徽章</div>
            </button>
            {/* <!-- Button when medal retrieved
            <button className="medal_btn_con medal_btn_done">
                <div className="medal_btn_text">鬍鬚徽章已領取</div>
            </button> */}
            <div className="remain_fd_con">尚餘3位好友</div>
        </div>
        
    </center>
      </body>
    </div>
  );
}

export default Share;
