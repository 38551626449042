import React from 'react';
import {  Routes, Route, Outlet } from "react-router-dom";

import Home from "./pages/Home";
import Selfie from "./pages/Selfie";
import Tutorial from "./pages/Tutorial";
import Slider from "./pages/Slider";
import Landing from "./pages/Landing";
import Share from "./pages/Share";
import Sticker from "./pages/Sticker";
import StickerEditor from './pages/StickerEditor';
import Quiz from './pages/Quiz';
import QuizLanding from './pages/QuizLanding';
import QuizResult from './pages/QuizResult';

export default function App(){
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Landing />} />
          <Route path="selfie" element={<Selfie />} />
          <Route path="slider" element={<Slider />} />
          <Route path="tutorial/:id" element={<Tutorial />} />
          <Route path="share" element={<Share />} />
          <Route path="sticker-editor" element={<StickerEditor />} />
          <Route path="sticker" element={<Sticker />} />
          <Route path="quiz-landing" element={<QuizLanding />} />

          <Route path="quiz" element={<Quiz />} />
          <Route path="quiz-result/:id" element={<QuizResult />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

