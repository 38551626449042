import styles from "../assets/styles/Cane.module.scss";
import caneLeft from "../assets/images/cane_left.png";
import caneRight from "../assets/images/cane_right.png";
import customSticker from "../assets/images/custom_sticker.png";
import xmasSticker from "../assets/images/manning_xmas_sticker.png";
import quiz_arrow from "../assets/images/quiz_arrow.png";


export default function CaneModule({onDIYClick, onDLClick}) {
  return (  
        <div className="candy_cane_con">
            <img src={caneLeft} className={styles.left_cane_con} alt='caneLeft'></img>
            <img src={caneRight} className={styles.right_cane_con} alt='caneRight'></img>
            <img src={customSticker} className={styles.custom_sticker_con} alt='customSticker'></img>
            <img src={xmasSticker} className={styles.xmas_sticker_con} alt='xmasSticker'></img>
            <div className={styles.custom_sticker_portal}>
                <div className={styles.cane_sticker_title}>自製<br></br>Sticker</div>
                <button onClick={onDIYClick} className={styles.custom_portal_btn}>
                    <div className={styles.cane_btn_text}>立即自製</div>
                    <div className={styles.cane_btn_icon}>
                        <img src={quiz_arrow} className={styles.cane_btn_img} alt='quiz_arrow'></img>
                    </div>
                </button>
            </div>
            <div className={styles.xmas_sticker_portal}>
                <div className={styles.cane_sticker_title}>萬寧聖誕<br></br>Sticker</div>
                <button onClick={onDLClick} className={styles.xmas_portal_btn}>
                    <div className={styles.cane_btn_text}>立即下載</div>
                    <div className={styles.cane_btn_icon}>
                        <img src={quiz_arrow} className={styles.cane_btn_img} alt='quiz_arrow'></img>
                    </div>
                </button>
            </div>
        </div>
  );
}