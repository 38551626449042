import LogoButtonModule from '../components/LogoButtonModule';
import styles from "../assets/styles/TutorialContent.module.scss";

export default function TutorialContentModule({index,desc,buttonImage, onClick}) {
  return (  
    <div className={styles.tutor_con}>
        <div>
            {/* <!-- Content Con --> */}
                <div className="tutor_text_con">
                    <p className="tutor_text">{desc}</p>
                </div>
                {/* <!-- Slider Number Con --> */}
                <div className="tutor_slide_num">{index}</div>
                {/* <!-- Tutorial Image Con --> */}
            <div className={styles.tutor_img_con}>
                <img src={buttonImage} className={styles.tutor_img} alt="tutorDummy"></img>
            </div>
            {/* <!-- Tutorial Continue Button Con --> */}
            <LogoButtonModule buttonCase={"TutorialNextButton"} onClick={onClick}/>
        </div>
    </div>
  );
}