import bgbtn from '../assets/images/back_btn.png';
import selfie_header_title from "../assets/images/selfie_header_title.png";
import bg_selfie from "../assets/images/bg_selfie.png";
import selfie_title from "../assets/images/selfie_title.png";
import selfie_dummy from "../assets/images/selfie_dummy.png";
import selfie_ig_portal from "../assets/images/selfie_ig_portal.png";
import Header from '../components/HeaderModule';
import { useNavigate } from "react-router-dom";

import '../assets/styles/Selfie.css';


function Selfie() {
  let navigate = useNavigate();
  return (
    <div>
      <body>
      <center className="selfie_center">
        <Header 
          hasTitle={true} 
          titleImage={selfie_header_title}
          backBtnOnClick={() => navigate('/tutorial/1')}
        />
        {/* <!-- Bg Con --> */}
        <img src={bg_selfie} className="selfie_bg" alt="bg-selfie"></img>  
        {/* <!-- Selfie Con --> */}
        <div className="selfie_title_con">
            <img src={selfie_title} className="selfie_title" alt="selfie-title"></img>
        </div>
        {/* <!-- Selfie Con --> */}
        <div className="selfie_con">
            <img src={selfie_dummy} className="selfie_img" alt="selfie-dummy"></img>
        </div>
        {/* <!-- IG Portal Con --> */}
        <div className="ig_con">
            <img src={selfie_ig_portal} className="ig_deco" alt="selfie-ig-portal"></img>
            <a className="ig_portal_btn"></a>
        </div>
    </center>
      </body>
    </div>
  );
}

export default Selfie;
