import React, { useRef, useLayoutEffect, useState, useEffect, Fragment } from "react";
import styles from "../assets/styles/StickerEditor.module.scss";
import { Stage, Layer, Star, Text, Image, Transformer, Group } from 'react-konva';
import useImage from 'use-image';

import redo_img from '../assets/images/redo.svg';
import undo_img from '../assets/images/undo.svg';
import close_img from '../assets/images/close.svg';
import trash_img from '../assets/images/trash-white.svg';

//sticker
import santa from '../assets/images/sticker/elements/santa.png';
import gift from '../assets/images/sticker/elements/gift.png';
import xmasHat from '../assets/images/sticker/elements/xmas-hat.png';
import beard from '../assets/images/sticker/elements/beard.png';
import tree from '../assets/images/sticker/elements/tree.png';
import merryXmas from '../assets/images/sticker/elements/merry-xmas.png';

let history = [[]];

let historyStep = 0;

export default function StcikerEditor(){

    const [uploadedImg, setUploadedImg]=useState(false);
    const [images, setImages] = useState([]);
    const [selectedId, selectShape] = useState(null);
    const [expandedEleBoard, setExpandedEleBoard]=useState(false);

    const handleUndo = () => {
        if (historyStep === 0) {
          return;
        }
        historyStep -= 1;
        const previous = history[historyStep];
        setImages(previous);
        selectShape(null);
    };

    const handleRedo = () => {
        if (historyStep === history.length - 1) {
          return;
        }
        historyStep += 1;
        const next = history[historyStep];
        setImages(next);
        selectShape(null);
    };

    const newHistory=(imgs)=>{
        history=history.slice(0,historyStep+1);
        history = history.concat([imgs]);
        historyStep += 1;
    }

    const deleteItem=()=>{
        if(selectedId!=null){
            var imgs=images.slice();
            imgs.splice(selectedId,1);
            setImages(imgs);
            newHistory(imgs);
            selectShape(null);
        }
    }

    const elements = [
        {
            src:santa,
            id: 'santa',
        },
        {
            src:gift,
            id: 'gift',
        },
        {
            src:xmasHat,
            id: 'xmas-hat',
        },
        {
            src:beard,
            id: 'beard',
        },
        {
            src:tree,
            id: 'tree',
        },
        {
            src:merryXmas,
            id: 'merry-xmas',
        }
    ];

    const stageRef = React.useRef(null);

    const checkDeselect = (e) => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
          selectShape(null);
        }
    };

    const downloadImage=async()=>{
        await selectShape(null);
        await timeout(500);
        if(images.length>0){
            var dataURL = stageRef.current.toDataURL({ pixelRatio: 1 });
            var link = document.createElement("a");
            link.download = 'sticker.png';
            link.href = dataURL;
            link.click();
        }
    }

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }


    const calcClipFunc=(ctx, x, y, width, height, radius) => {
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.closePath();
    }

    const URLImage = ({ image, isSelected, onSelect, onChange }) => {
        const [img] = useImage(image.src);
        const trRef = useRef();
        const shapeRef = React.useRef();

        useEffect(() => {
            if (isSelected) {
              // we need to attach transformer manually
              trRef.current.nodes([shapeRef.current]);
              trRef.current.getLayer().batchDraw();
            }
        }, [isSelected]);
        return (
            <Fragment>
                <Image
                    onClick={onSelect}
                    onTap={onSelect}
                    image={img}
                    ref={shapeRef}
                    {...image}
                    draggable
                    // I will use offset to set origin to the center of the image
                    offsetX={img ? Math.min(window.innerWidth*0.9,img.width) / 2 : 0}
                    offsetY={img ? Math.min(window.innerWidth*0.9,img.height) / 2 : 0}
                    width={image && "width" in image?image.width:(img?Math.min(img.width,window.innerWidth*0.9)/img.width*img.width:0)}
                    height={image && "height" in image?image.height:(img?Math.min(img.width,window.innerWidth*0.9)/img.width*img.height:0)}
                    onDragEnd={(e) => {
                        onChange({
                          ...image,
                          x: e.target.x(),
                          y: e.target.y(),
                        });
                    }}
                    onTransformEnd={(e) => {
                        // transformer is changing scale of the node
                        // and NOT its width or height
                        // but in the store we have only width and height
                        // to match the data better we will reset scale on transform end
                        const node = shapeRef.current;
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();
              
                        // we will reset it back
                        node.scaleX(1);
                        node.scaleY(1);
                        //console.log(node);
                        onChange({
                          ...image,
                          x: node.x(),
                          y: node.y(),
                          rotation:node.rotation(),
                          // set minimal value
                          width: Math.max(5, node.width() * scaleX),
                          height: Math.max(node.height() * scaleY),
                        });
                    }}
                />
                {isSelected && (
                    <Transformer
                        ref={trRef}
                        boundBoxFunc={(oldBox, newBox) => {
                            // limit resize
                            if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                            }
                            return newBox;
                        }}
                    />
                )}
            </Fragment>
        );
    };
    

    return (
        <div className={styles.container}>
            <div className={styles.panel}>
                <div className={styles.topOverlay}></div>
                <div className={styles.content}>
                    <div className={styles.toolbar}>
                        <div className={styles.doBtnGp}>
                            <button onClick={handleUndo} className={`${styles.doBtn} ${styles.undoBtn} ${historyStep>0?styles.active:""}`}><img src={undo_img}></img></button>
                            <button onClick={handleRedo} className={`${styles.doBtn} ${styles.redoBtn} ${historyStep<history.length-1?styles.active:""}`}><img src={redo_img}></img></button>
                        </div>
                        <div className={styles.downloadBtnGp}>
                            <button onClick={downloadImage} className={`${styles.downloadBtn} ${images.length>0?styles.active:""}`}>下載Sticker</button>
                        </div>
                    </div>
                    <div className={styles.canvasDiv}>
                        {images.length<=0 && (<div className={styles.uploadDiv}>
                            <input
                                type="file"
                                id="uploadImage"
                                name="uploadImage"
                                onChange={(event) => {
                                    console.log(event.target.files[0]);
                                    console.log(window.innerWidth*0.9*0.5);

                                    var imgs=images.concat([
                                        {
                                            x:window.innerWidth*0.9*0.5,
                                            y:window.innerWidth*0.9*0.5,
                                            src: URL.createObjectURL(event.target.files[0]),
                                        },
                                    ]);
                                    
                                    setImages(imgs);
                                    newHistory(imgs);
                                }}
                            />
                            <button className={styles.uploadBtn} onClick={()=>{document.querySelector("#uploadImage").click();}}>上載圖片</button>
                        </div>)}
                        {images.length>0 && (
                        <Stage 
                            width={window.innerWidth*0.9} 
                            height={window.innerWidth*0.9}
                            onMouseDown={checkDeselect}
                            onTouchStart={checkDeselect}
                            ref={stageRef}
                        >
                            <Layer>
                                <Group
                                    clipFunc={ctx => calcClipFunc(ctx, 0, 0, window.innerWidth*0.9, window.innerWidth*0.9, 30)}
                                >
                                    {images.map((image,index) => {
                                        return (
                                            <URLImage 
                                                key={index} 
                                                image={image}
                                                isSelected={index === selectedId}
                                                onSelect={() => {
                                                    selectShape(index);
                                                }}
                                                onChange={(newAttrs) => {
                                                    console.log(newAttrs);
                                                    const imgs = images.slice();
                                                    console.log(imgs);
                                                    imgs[index] = newAttrs;
                                                    setImages(imgs);
                                                    newHistory(imgs);
                                                }}
                                            />
                                        );
                                    })}
                                </Group>
                            </Layer>
                        </Stage>)}
                    </div>
                </div>
                
            </div>
            <div className={styles.control}>
                <div className={`${styles.eleBoardDiv} ${expandedEleBoard?styles.expanded:""}`}>
                    <div className={`${styles.eleBoard} ${expandedEleBoard?styles.expanded:""}`}>
                        <button onClick={()=>{setExpandedEleBoard(false);}} className={`${styles.closeBtn} ${expandedEleBoard?styles.expanded:""}`}><img src={close_img}/></button>
                        <button onClick={()=>{setExpandedEleBoard(true);selectShape(null);}} className={styles.expandBtn}>素材</button>
                        <div className={styles.eleShowArea}>
                            <div className={styles.eleScrollView}>
                                {elements.map((ele,i)=>(
                                    <button key={i} className={styles.eleItem} onClick={()=>{
                                        var imgs=images.concat([
                                            {
                                                x:window.innerWidth*0.9*0.5,
                                                y:window.innerWidth*0.9*0.5,
                                                src: ele.src,
                                            },
                                        ]);
                                        setImages(imgs);
                                        newHistory(imgs);
                                        setExpandedEleBoard(false);
                                    }}><img src={ele.src}/></button>
                                ))}
                            </div>
                        </div>
                        <button onClick={()=>{deleteItem();}} className={`${styles.deleteBtn} ${selectedId!=null?styles.selected:""}`}><img src={trash_img}/></button>
                    </div>
                </div>
            </div>
        </div>
    )
}